<script setup lang="ts">
import Tracker from '~/libs/Tracker';

import StudyRoomCertificates from '~/services/mypage/component/studyroom/StudyRoomCertificates.vue';
import StudyRoomClasses from '~/services/mypage/component/studyroom/StudyRoomClasses.vue';
import StudyRoomReports from '~/services/mypage/component/studyroom/StudyRoomReports.vue';
import StudySurveys from '~/services/mypage/component/studyroom/StudySurveys.vue';

const route = useRoute();
const router = useRouter();
const tabIdx = computed<number>({
  get: () => Number(route.query?.tab) || 0,
  set: (index) => {
    router.push({ query: { tab: index } });
  },
});

useHead({
  htmlAttrs: {
    class: 'mobile-layout-hide',
  },
});

const changeTab = (data: { index: number }) => {
  tabIdx.value = data.index;
};

let unlisten = () => {};
onMounted(() => {
  unlisten = router.beforeEach(async (to, from, next) => {
    if (to.path === '/payment' && from.path === '/mypage/studyroom') {
      await $alert('결제가 완료되었습니다.');
      return next(false);
    }

    return next();
  });
});

onBeforeMount(() => {
  if (!window) {
    return;
  }

  const previousUrl = window?.history?.state?.back;
  if (!previousUrl) {
    return;
  }

  const a = document.createElement('a');
  a.href = previousUrl;
  Tracker['View My Classroom Menu']({
    referrer_url: `${window.origin}${previousUrl}`,
    referrer_path: a.pathname,
  });
});

onBeforeUnmount(() => {
  unlisten();
});
</script>

<template>
  <div class="wb-mypage__contents">
    <TabView :active-index="tabIdx" @tab-change="changeTab">
      <TabPanel header="클래스" lazy>
        <StudyRoomClasses v-if="tabIdx === 0" />
      </TabPanel>
      <TabPanel header="과제관리" lazy>
        <StudyRoomReports v-if="tabIdx === 1" />
      </TabPanel>
      <TabPanel header="조편성" lazy>
        <StudySurveys v-if="tabIdx === 2" />
      </TabPanel>
      <TabPanel header="수료증" lazy>
        <StudyRoomCertificates v-if="tabIdx === 3" />
      </TabPanel>
    </TabView>
  </div>
</template>

<style scoped></style>
